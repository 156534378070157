<template>
  <div class="flex flex-column justify-content-around align-items-center flex-grow-1 min-h-screen">
    <div class="bg-transparent shadow-2 border-black-alpha-30 relative mt-4 cursor-pointer" style="max-width:100vw" @click="goto('/my')">
      <img src="../assets/logo.png" width="16" height="16" class="absolute left-0 bottom-0 z-0">
      <img src="../assets/krzwrtrstsl-fs8.png" style="max-height: 40vh; max-width:80vw;">
      <div class="text-xl text-primary z-1 absolute top-0 right-0 p-1">Crosswords AI</div>
    </div>
    <div class="text-center mb-5 mt-3">
      <div class="text-3xl text-primary flex flex-column justify-content-start align-items-center mb-1">
        <div class="flex flex-column md:flex-row align-items-center mt-2">
          <div class="p-2">
            <Button @click="goto('/create')" class="p-button-primary">
              <div class="flex flex-row align-items-center">
                <i class="pi pi-plus text-2xl"></i>
                <div class="flex flex-column align-items-start ml-2">
                  <div>Kreuzworträtsel</div>
                  <small>erzeugen</small>
                </div>
              </div>
            </Button>
          </div>
          <div class="flex flex-row my-5 m-md-0" id="create-or-play">
            <div class="flex-grow-1 flex align-items-center" style="min-width:100px">
              <div class="w-full border-primary border-primary border-bottom-1"></div>
            </div>
            <div class="pl-3 pr-3 pt-1 pb-1 bg-primary text-white text-lg">ODER</div>
            <div class="flex-grow-1 align-items-center"></div>
            <div class="flex-grow-1 flex align-items-center" style="min-width:100px">
              <div class="w-full border-primary border-primary border-bottom-1"></div>
            </div>
          </div>
          <div class="p-2">
            <Button @click="goto('/random-game')" class="p-button-secondary">
              <div class="flex flex-row align-items-center">
                <div class="flex flex-column align-items-start">
                  <div>Zufälliges Spiel</div>
                  <small>starten</small>
                </div>
                <i class="pi pi-play text-2xl ml-1"></i>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  props: {
    msg: String
  },
  data() {
    return {
      userId:0
    }
  },
  created() {
    this.userId = (this.authService.getLocalProfile()||{}).id;
  },
  methods:{
    goto(path="/") {
      this.$router.push(path);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
a {
  color: #42b983;
}
ol { counter-reset: item; padding:0; line-height: 1.75;
  display: flex; flex-direction: column }
ol>li { display: flex; flex-direction: row }
ol>li:before {
  content: counter(item) ".\00a0\00a0";
  counter-increment: item;
  display: inline-block;
}
</style>
