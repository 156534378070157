<template>
  <!-- display sidebar if current route is a descendant of a tab root -->
  <cf-container :class="$route.path.match(/^\/((home)|(create)|(my))\/?/) ? 'bg-home' : ''">
    <cf-sidebar :class="`flex flex-column ${$route.path.match(/^\/((home)|(game)|(random-game)|(login)|(register)|(print-game))\/?/) ? '' : 'active'}`">
      <TabMenu :model="filteredTabs" @tab-change="onTabChange" :active-index="activeIndex" :class="'main-menu flex-grow-1'">
        <template #item="{item}">
          <a role="menuitem" class="p-menuitem-link" aria-label="Container" tabindex="0"><span :class="'p-menuitem-icon '+item.icon"></span><span class="p-menuitem-text" v-html="item.label"></span></a>
        </template>
      </TabMenu>
      <div class="p-tabmenu p-component main-menu">
        <ul class="p-tabmenu-nav p-reset" role="tablist">
          <li class="p-tabmenuitem" role="tab">
            <a target="_blank" class="p-menuitem-link" role="presentation" href="/dokumentation/">
              <span class="p-menuitem-icon pi pi-question-circle"></span><span class="p-menuitem-text">Hilfe</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="p-tabmenu p-component main-menu">
        <ul class="p-tabmenu-nav p-reset" role="tablist">
          <li class="p-tabmenuitem" role="tab">
            <a class="p-menuitem-link" role="presentation" @click.prevent="logout" v-if="localProfile">
              <span class="p-menuitem-icon pi pi-sign-out"></span><span class="p-menuitem-text"><small style="display:inline-block;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:75px">{{localProfile.username}}</small><br>Abmelden</span>
            </a>
            <a class="p-menuitem-link" role="presentation" @click.prevent="login" v-else>
              <span class="p-menuitem-icon pi pi-sign-in"></span><span class="p-menuitem-text">Anmelden</span>
            </a>
          </li>
        </ul>
      </div>
    </cf-sidebar>
    <cf-content>
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition||'slidefade'" mode="out-in">
          <component :is="Component" />
        </transition>
        <div id="phaser-container"
             :class="`${$route.path.match(/^\/game/) ? 'opacity-100' : 'opacity-0'}`"
             :style="`${$route.path.match(/^\/game/) ? 'z-index: 0' : 'z-index:-1'}`"
        ></div>
      </router-view>
    </cf-content>
  </cf-container>
  <ConfirmDialog></ConfirmDialog>
  <DynamicDialog></DynamicDialog>
  <Toast></Toast>
</template>
<script>
import {singletons} from "../shared";

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      tabs: [
        {label: 'Start', root:"/home", icon:"pi pi-home"},
        {label: 'Meine<br><small>Kreuzworträtsel</small>', root:"/my", icon:"pi pi-sitemap", userlevel: 0},
        {label: 'System', root: '/system', icon:"pi pi-bolt", userlevel: 2},
      ],
      activeIndex:0,
      sidebarVisible:true,
      localProfile:null
    }
  },
  watch:{
    $route (){
      this.onRouteChange(this.$route.path);
    }
  },
  mounted() {
    this.onRouteChange(this.$route.path);
    function refreshVars() {
      let sidebar = document.querySelector("cf-sidebar");
      if (sidebar)
        document.documentElement.style.setProperty('--sidebar-width',`${sidebar.clientWidth}px`);
    }
    window.addEventListener('resize', () => {
      refreshVars()
    });
    // needed for reactivity (filteredTabs)
    window.addEventListener('auth-change',()=>{
      this.localProfile = this.authService.getLocalProfile();
    });
  },
  async created() {
    this.localProfile = this.authService.getLocalProfile();
  },
  methods: {
    onTabChange(e) {
      this.$router.push(this.activeIndex===e.index ? this.tabs[e.index].root  : this.tabs[e.index].path || this.tabs[e.index].root)
    },
    onRouteChange(currentPath) {
      let i=0;
      while (i<this.tabs.length && !currentPath.match(new RegExp(`^${this.tabs[i].root}([/]|$)`))) {
        i++;
      }
      if (i<this.tabs.length) {
        this.tabs[i].path = currentPath;
        this.activeIndex = i;
      }
    },
    async logout() {
      await this.authService.logout();
      singletons.sockets = {};
      this.login();
    },
    login() {
      this.$router.push("/login");
    }
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter(tab=>(tab.userlevel||-1)<=(this.localProfile ? this.localProfile.level||-1 : -1));
    }
  }
}
</script>
<style>
.slidefade-enter-active,
.slidefade-leave-active,
.slidefade-reverse-enter-active,
.slidefade-reverse-leave-active {
  transition: all 0.2s ease;
}
.slidefade-enter-from, .slidefade-reverse-leave-to {
  //transform: translateY(20px);
  opacity: 0;
}
.slidefade-leave-to, .slidefade-reverse-enter-from {
  //transform: translateY(-20px);
  opacity: 0;
}
</style>